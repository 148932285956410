<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getNewsletterStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getNewsletterError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('global:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="getNewsletterModal.email"
                                            :label="`${$t(
                                                'newsletter:email'
                                            )}*`"
                                            :error-messages="
                                                getNewsletterError.stepOne
                                                    .fields.email
                                            "
                                        ></v-text-field> </v-col
                                ></v-row>
                                <v-row
                                    ><v-col cols="5">
                                        <v-select                                            
                                            v-model="
                                                getNewsletterModal.newsletterKind
                                            "
                                            :error-messages="
                                                getNewsletterError.stepOne
                                                    .fields.newsletterKind
                                            "
                                            :items="
                                                getRegistryDictionaries.newsletterKind
                                                    ? getRegistryDictionaries.newsletterKind
                                                    : []
                                            "
                                            :label="`${$t(
                                                'newsletter:newsletterKind'
                                            )}*`"
                                            return-object
                                            item-value="field"                                            
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select></v-col
                                    ></v-row
                                >
                                <v-row
                                    ><v-col>
                                        <v-textarea
                                            v-model="
                                                getNewsletterModal.description
                                            "
                                            :label="`${$t(
                                                'newsletter:description'
                                            )}`"
                                            no-resize
                                            rows="1"
                                            auto-grow
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
        }
    },
    methods: {
        ...mapActions([]),
        ...mapMutations([]),
    },
    computed: {
        ...mapGetters([
            'getRegistryDictionaries',
            'getNewsletterModal',
            'getNewsletterError',
            'getNewsletterStep',
        ]),
    }
}
</script>
