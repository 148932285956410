var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getNewsletterStep.step),callback:function ($$v) {_vm.$set(_vm.getNewsletterStep, "step", $$v)},expression:"getNewsletterStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getNewsletterError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('global:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'newsletter:email'
                                        )}*`,"error-messages":_vm.getNewsletterError.stepOne
                                                .fields.email},model:{value:(_vm.getNewsletterModal.email),callback:function ($$v) {_vm.$set(_vm.getNewsletterModal, "email", $$v)},expression:"getNewsletterModal.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"error-messages":_vm.getNewsletterError.stepOne
                                                .fields.newsletterKind,"items":_vm.getRegistryDictionaries.newsletterKind
                                                ? _vm.getRegistryDictionaries.newsletterKind
                                                : [],"label":`${_vm.$t(
                                            'newsletter:newsletterKind'
                                        )}*`,"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getNewsletterModal.newsletterKind
                                        ),callback:function ($$v) {_vm.$set(_vm.getNewsletterModal, "newsletterKind", $$v)},expression:"\n                                            getNewsletterModal.newsletterKind\n                                        "}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'newsletter:description'
                                        )}`,"no-resize":"","rows":"1","auto-grow":""},model:{value:(
                                            _vm.getNewsletterModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getNewsletterModal, "description", $$v)},expression:"\n                                            getNewsletterModal.description\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }